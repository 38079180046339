<template>
	<v-container>
		<div v-if="thisParcours" class="container-block">
			<CongrateFinishParcours
				:parcours="thisParcours"
				:class="{ 'mt-7': $vuetify.breakpoint.mdAndUp, 'mt-2': $vuetify.breakpoint.smAndDown }"
			/>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<SessionPerformance
				v-if="parcoursScore"
				:totalScoreInPercent="parcoursScore"
				:title="'Performance globale'"
				:subTitlePassed="`C'est ta moyenne de bonnes réponses sur le parcours`"
				:subTitleNotPassed="`C'est ta moyenne de bonnes réponses sur le parcours`"
			/>

			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<TotalEarnedPointsRedaction :parcours="thisParcours" />
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<TrackPerformance
				v-if="formationProgress && formationProgress.config.topic_id.slug === 'redaction'"
				title="Ma qualité d’expression"
				subTitle="C’est la moyenne de bonnes réponses pour les questions de type expression"
				:score="calculateScoreForTracksByType('Expression')"
				colColor="#3cdcff"
			/>

			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
			<TrackPerformance
				title="Ma qualité de compréhension"
				subTitle="C’est la moyenne de bonnes réponses pour les questions de type QCM"
				:score="calculateScoreForTracksByType('QCM')"
				colColor="#FD9905"
			/>

			<v-btn
				v-if="notRedoExercise"
				depressed
				large
				dark
				color="black"
				class="next-btn hvr-grow"
				@click="directToUnlockParcours"
			>
				<span class="white--text">Voir le parcours suivant</span>
			</v-btn>
			<div :class="{ 'pt-5 pb-5': $vuetify.breakpoint.mdAndUp, 'pt-2 pb-2': $vuetify.breakpoint.smAndDown }"></div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import SessionPerformance from '@/components/session-result/SessionPerformance.vue';
import CongrateFinishParcours from '@/components/reports/parcours/CongrateFinishParcours';
import TotalEarnedPointsRedaction from '@/components/reports/parcours/TotalEarnedPointsRedaction.vue';
import TrackPerformance from '@/components/reports/parcours/TrackPerformance.vue';

import { changeBgColorById } from '@/utils/css-util.js';
import { setZindexByClassNames } from '@/utils/helper.js';

export default {
	name: 'ParcoursReport',
	components: { CongrateFinishParcours, SessionPerformance, TotalEarnedPointsRedaction, TrackPerformance },
	data() {
		return { thisParcours: null };
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
		notRedoExercise() {
			return this.$route.query.notRedo === 'true';
		},
		parcoursScore() {
			return this.calculateParcoursPercentScore();
		},
	},
	async created() {
		const formationProgressRes = await this.$store.dispatch('profile/formationProgress', {
			idFormation: this.$route.params.idFormation,
		});
		const idParcours = this.$route.params.idParcours;
		const indexOfParcours = formationProgressRes.config.parcours_list.findIndex(
			(parcoursItem) => parcoursItem.parcours_id._id === idParcours
		);
		if (indexOfParcours !== -1)
			this.thisParcours = formationProgressRes.config.parcours_list[indexOfParcours].parcours_id;
	},
	mounted() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		changeBgColorById('reportIndex', '#F0F0FC');
		setZindexByClassNames(['navbar'], 1);
	},
	methods: {
		directToUnlockParcours() {
			this.$router.push({ name: 'ChooseParcousToUnlock', params: { idFormation: this.$route.params.idFormation } });
		},
		calculateParcoursPercentScore() {
			let countQuestionnaire = 0;
			let totalQuestionnaireScore = 0;
			this.thisParcours.categories.forEach((category) => {
				category.sessions.forEach((session) => {
					session.sessionBlocks.forEach((block) => {
						if (block.type === 'training') return;
						block.questionnaires.forEach((questionnaire) => {
							countQuestionnaire += 1;
							if (questionnaire.questionnaireScore) totalQuestionnaireScore += questionnaire.questionnaireScore;
						});
					});
				});
			});
			if (countQuestionnaire === 0) return null;
			return totalQuestionnaireScore / countQuestionnaire;
		},
		calculateScoreForTracksByType(questionType) {
			let totalCoef = 0;
			let totlScore = 0;
			this.thisParcours.categories.forEach((category) => {
				category.sessions.forEach((session) => {
					session.sessionBlocks.forEach((block) => {
						if (block.type === 'training') return;
						block.questionnaires.forEach((questionnaire) => {
							questionnaire.tracks.forEach((track) => {
								if (track.question_type !== questionType) return;
								totalCoef += track.coefficient;
								totlScore += track.trackResponse.score * track.coefficient;
							});
						});
					});
				});
			});
			if (totalCoef === 0) return 0;
			return Math.ceil(totlScore / totalCoef);
		},
	},
};
</script>

<style lang="scss" scoped>
.container-block {
	margin: 0 auto;
	padding-bottom: 40px;
	max-width: 800px;
}
.next-btn {
	position: fixed;
	bottom: 30px;
	left: 50%;
	transform: translateX(-50%);
}
</style>
